import React from "react";
import styled from 'styled-components';
import { graphql, StaticQuery } from "gatsby";
import Helmet from "react-helmet";
import TagCloud from "../components/TagCloud";
import {MAX_MAIN_WIDTH, PAGE_PADDING} from "../styles/dimens";
import {DOMAIN} from "../constants";

const Container = React.memo(styled.main`
  padding: ${PAGE_PADDING};
  max-width: ${MAX_MAIN_WIDTH};
  box-sizing: border-box;
  margin: auto;
  text-align: center;
`);

const CategoriesPage = React.memo(() => (
  <StaticQuery
  query={ graphql`
    query CategoriesQuery {
      allDataJson {
        edges {
          node {
            categories {
              title
              slug
              topics {
                title
              }
            }
          }
        }
      }
    }
` }
  render={ (data) => {
    const node = data.allDataJson.edges[0].node;
    const categories = node['categories'];
    return (
      <Container>
        <Helmet>
          <title>Recipe Watch</title>
          <meta name="description"
                content="Keeping a look out for delicious and healthy recipes on the net." />
          <meta property="og:title" content='Recipe Watch' />
          <meta property="og:type" content="website" />
          <link rel="canonical" href={`${DOMAIN}/categories`} />
        </Helmet>
        <h1>Categories</h1>
        <TagCloud tags={categories.map(c => ({...c, count: c.topics.length}))} />
      </Container>
    );
}} />));

export default CategoriesPage

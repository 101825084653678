import React from "react";
import { TagCloud as ReactTagCloud } from 'react-tagcloud'
import {Link} from 'gatsby';

const MAX_SIZE = 36;
const MIN_SIZE = MAX_SIZE / 2;

const customRenderer = (tag, size, color) => {
  return (
    <div key={tag.slug}
          style={{
            fontSize: size,
            margin: 6,
            whiteSpace: 'nowrap',
            display: 'inline-block',
            verticalAlign: 'middle'
          }}>
      <Link style={{color}} to={`/categories/${tag.slug}`}>
        {tag.title}
      </Link>
    </div>
  )
}

const TagCloud = React.memo(({tags}) => (
  <ReactTagCloud
    minSize={MIN_SIZE}
    maxSize={MAX_SIZE}
    tags={tags}
    colorOptions={{luminosity: 'bright', hue: '#6bf'}}
    renderer={customRenderer}
  />
));

export default TagCloud
